'use client'

import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useEffect } from 'react';

interface shortTermGoalsData {
	title: string;
	description: string[];
}

export default function LandingPageComponent() {
	const shortTermGoals: shortTermGoalsData[] = [
		{
			title: 'Split into research teams',
			description: [
				'[1.] - Business, Finance & Marketing (BFM): Organisational Structure & Economic Rewards of Academia;',
				'[2.] - Biology & Human Anatomy (BHA): Physiology of Learning & Developing;',
				'[3.] - Cognitive Neuropsychology (CNP): Psychology of Learning & Developing',
				'[4.] - Computer Science (CS): Digitalisation of Learning & Developing',
			],
		},
		{
			title: 'Conduct research',
			description: [
				'[1.] - BFM: Anthropology, Economics, and Sociology;',
				'[2.] - BHA: Biochemistry and Neuroscience;',
				'[3.] - CNP: Neuroscience and Psychology;',
				'[4.] - CS: Computer Science, Cryptography, and Software Engineering',
			],
		},
		{
			title: 'Build internal tools',
			description: [
				'[1.] - HOST LATEX EDITOR:',
				'[1.1.] - Write and compile PDF documents,',
				'[1.2.] - Host grammarly API to ensure high quality writing;',
				'[2.] - HOST MULTIMODAL AIs (i.e., LLM, Computer Vision, text-image, text to video):',
				'[2.1.] - Description & categorisation of data',
				'[2.2.] - Generation of metadata',
				'[2.3.] - Change writing style based on a user',
				'[3.] - HOST AN INTEGRATED DEVELOPMENT ENVIRONMENT (IDE):',
				'[3.1.] - Develop simulations and run code',
				'[4.] - DEVELOP STEPS:',
				'[4.1.] - Enable tracking and replicating of work and findings',
				'[4.2.] - Tool to perform data science (analysis, visualisation, )',
				'[5.] - PUBLISHING:',
				'[5.1.] - Create sets of rules/guidelines to create baseline content (i.e., for creator-specific user-profile: e.g., doctorate, master, bachelor)',
				'[5.2.] - Employ Footsteps to track progress and generate rewards (individual economic value)',
				'[6.] - REVIEWING:',
				'[6.1.] - Create sets of rules to validate and certify publications (i.e., for viewer-specific user-profile: e.g., doctorate, master, bachelor)',
				'[6.2.] - Employ Footsteps to track progress and generate rewards (individual economic value)',
				'[7.] - LEARNING & DEVELOPING (∀ ages and expertises. Learners & developers ⊃ researchers, students, etc.):',
				'[7.1.] - Employ the generative AIs to create non-baseline content/tailored data (i.e., papers, slideshows, videos, and transcriptions)',
				'[7.2.] - Employ Footsteps to enable tracking and/or replicating of scientific work and/or findings',
				'[7.3.] - Employ IDE for application of learned material',
				'[7.4.] - Employ Footsteps to track progress and generate rewards (individual economic value).',
			],
		},
		{
			title: 'Create a transparent research environment',
			description: [
				'[1.] - Society (Incentivise Using Learning & Development):',
				'[1.1.] - Develop an economic system based on [1.1.1.]  - Learn&Develop-to-earn as a society rather than the common play-to-earn as an individual',
				'[1.1.1.] - Transparency as a key aspect of earning',
				'[2.] - The Publishing Industry (Keeping Science Behind a Paywall for All the Right Reasons):',
				'[2.1.] - Track progress from planning to initiating research to publication to reviewing (i.e., Footsteps)',
				'[2.1.1.] - Increase the transparency of science,',
				'[2.1.2.] - The reviewers can more easily inspect and review research,',
				'[2.1.3.] - Help the publishers pursue more diverse research and approaches + the pursuit of reproducibility and replicability,',
				'[2.1.4.] - Encourage and help the academic community to learn and develop,',
				'[2.2.] - Track progress of learning and developing',
				'[2.2.1.] - Royalties for intellectual property rights through accessing and citing of material (manuscript, page, paragraph, etc.);',
				'[3.] - The Reviewing Industry (Incentivising & Optimally Rewarding Academics for Imperative Tasks):',
				'[3.1.] - Track progress from initiating research to publication of review (i.e., Footsteps)',
				'[3.1.1.] - Increase the transparency of science,',
				'[3.1.2.] - The reviewing industry inspect and review,',
				'[3.1.3.] - The academic community learn and develop,',
				'[3.2.] - Track progress of learning and developing',
				'[3.2.1.] - Royalties for intellectual property rights through accessing and citing of validated reviews (manuscript, page, paragraph, etc.)',
				'[4.] - Self-funding Academics/Researchers/Learners/Developers:',
				'[4.1.] - Learn&Develop-to-earn (cryptocurrency);',
				'[5.] - The Academic Industry (Easy to Access & Easy to Teach):',
				'[5.1.] - Open access material + open access & open source tools,',
				'[5.2.] - Customised UI/UX through statistical learning methods allow optimal learning for individuals.',
			],
		},
		{
			title: 'Reach out to researchers/professors',
			description: [
				'[6.]Reach out to researchers/professors (BEING DOCUMENTED)',
			],
		},
		{
			title: 'Long Term Goals',
			description: [
				'[7.1] - Collaborate with researchers/professors to validate our research',
				'[7.2] - Create a public beta program to build higher level system architecture',
				'[7.3] - Provide software that helps people navigate information intuitively using human intuition and guided by mathematics',
			],
		},
	];

	const onScroll = useCallback(() => {
		const reveals = document.querySelectorAll('.reveal');
		for (let i = 0; i < reveals.length; i++) {
			const windowHeight = window.innerHeight;
			const elementTop = reveals[i].getBoundingClientRect().top;
			const elementVisible = 150;
			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", onScroll);
		}
	}, [onScroll]);

	return (
		<main >
			<div
				id="human-focused-technology-intro"
				className="grid grid-cols-1 content-center justify-items-center p-4 gap-2 bg-slate-900 min-h-[1200px] pb-32 min-w-full w-screen"
			>
				<div id="human-focused-technology-content" className="max-w-[800px]">
					<div
						id="landing_page_intro"
						className="grid grid-cols-1 gap-2 justify-items-center pb-8"
					>
						<span className="text-white text-lg"> Welcome to </span>
						<span className="text-[#D0BCFF] text-6xl">
							<b>COVALENCE</b>
						</span>
						<span className="text-white text-lg">
							The Global Academic Society
						</span>
						<h5>
							<cite>
								&apos;If I have seen further, it is by standing on the shoulders of
								giants.&apos;
							</cite>
							- Sir Isaac Newton, 1675 [1642 - 1727]
						</h5>

						<span className="text-2xl md:text-6xl"
						>We develop tools that help researchers thrive.
						</span>
					</div>
					<div
						id="human-focused-technology-info"
						className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8"
					>
						<div
							id="human-focused-technology-text"
							className="grid grid-cols-1 gap-2"
						>
							<span className="text-lg">
								The research industry faces various challenges that can impede
								research efforts. One such obstacle is securing funding due to
								high costs and prolonged durations. Additionally, finding
								competent and dedicated researchers can be challenging, given
								the specialized expertise required. Ensuring research accuracy
								and ethical considerations also pose significant difficulties,
								with factors like data bias and participant privacy concerns to
								navigate.
							</span>

							<span className="text-lg">
								Covalence provides tools and services for independent
								researchers to conduct studies using the latest technology in
								one place, with an elevated review system and integrated
								workflows for both institutional and solo work.
							</span>
						</div>
							<Image
							className="shadow-md shadow-black rounded-md hover:shadow-2xl hover:shadow-black ease-in-out hover:-translate-y-2 transition"
								height={320}
								width={480}
							src="https://firebasestorage.googleapis.com/v0/b/covalence-frontend-alpha.appspot.com/o/landingpage%2Fchildren_playing_swing.webp?alt=media&token=17b13cb8-9a61-4fa9-b756-31a02335e347"
								alt=""
						/>

					</div>

					<CallToActionCard />
				</div>
			</div>
			<div
				id="human-focused-technology-steps"
				className="grid grid-cols-1 content-center justify-items-center gap-2 text-slate-900 bg-white py-8 min-w-full"
			>
				<div
					id="human-focused-technology-intro"
					className="grid grid-cols-1 content-center max-w-[800px] min-h-[720px] reveal p-4 mb-8"
				>
					<span className="text-4xl mb-4">Follow in eachother&apos;s footsteps.</span>
					<div
						id="human-focused-technology-content"
						className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-4"
					>
						<Image
							className="shadow-md shadow-black rounded-md hover:shadow-2xl hover:shadow-black ease-in-out hover:-translate-y-2 transition"
							src="https://firebasestorage.googleapis.com/v0/b/covalence-frontend-alpha.appspot.com/o/landingpage%2Ffishing_boats.webp?alt=media&token=c50b86ef-a5c0-432f-9fda-2a03a09371ab"
							alt=""
							height={320}
							width={480}
						/>
						<span className="text-lg">
							Let your academic journey inspire and empower others to create a
							brighter future for humanity. Open up new frontiers of exploration
							and innovation by sharing, storing, and nurturing your neural
							pathways during research, enabling yourself and fellow humans to
							navigate information with greater precision and discover uncharted
							territories with the help of machine learning.
						</span>
					</div>
				</div>
			</div>
			<div
				id="navigate-information"
				className="grid grid-cols-1 content-center justify-items-center gap-2 text-white bg-slate-900 py-12 min-w-full"
			>
				<div
					id="human-focused-technology-intro"
					className="grid grid-cols-1 content-center max-w-[800px] min-h-[720px] reveal p-4"
				>
					<span className="text-6xl">Navigate the vector space with ease.</span>
					<div
						id="human-focused-technology-content"
						className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-8"
					>
						<span className="text-lg">
							Build stronger connections with both general information and
							people by finding patterns and strengthening bonds. Data science
							can be made easier and more flexible with our tools, which aim to
							facilitate both individual and collaborative learning.
						</span>
						<Image
							className="shadow-md shadow-black rounded-md hover:shadow-2xl hover:shadow-black ease-in-out hover:-translate-y-2 transition"
							src="https://firebasestorage.googleapis.com/v0/b/covalence-frontend-alpha.appspot.com/o/landingpage%2Fhuman_art.webp?alt=media&token=9616d7cc-303e-4043-bb7b-bffa5f08e340"
							alt=""
							height={320}
							width={480}
						/>
					</div>
				</div>
			</div>
			<div
				id="open-access-information"
				className="grid grid-cols-1 p-4 justify-items-center gap-2 text-black bg-white min-w-full"
			>
				<div
					id="open-access-information-intro"
					className="grid grid-cols-1 gap-2 content-center max-w-[800px] min-h-[720px] h-[800px] reveal "
				>
					<span className="text-6xl">Learn with freedom</span>
					<span className="text-2xl italic mt-2 mb-8">
						Open access to academic information. We value the information you add back to
						the system.
					</span>
					<a
						href="/"
						className="underline text-xl md:text-md decoration-solid hover:decoration-sky-500"
					>
						learn more here
					</a>
					<CallToActionCard />
				</div>
				
			</div>
			<div
				id="future-prospects"
				className="grid grid-cols-1 content-center justify-items-center gap-2 text-white bg-slate-900 p-4 min-w-full"
			>
				<span className="text-6xl my-12">Public Timeline</span>
				<div id="short-term-goals" className="grid grid-cols-1">
					<span className="text-4xl text-white mb-8">SHORT TERM GOALS</span>
					<ul
						className="border-l border-gray-300"
					>
						{
							shortTermGoals.map((shortTermGoal, i) => {
								return (
									<li key={shortTermGoal.title + i}>
										<div className="flex flex-start">
											<div
												className="bg-sky-500 w-4 h-4 rounded-full -ml-2 mr-3 hover:animate-ping"
											></div>
											<div className="grid grid-col-1 mb-4">
												<span className="text-sky-500  text-2xl hover:text-sky-200">{
													shortTermGoal.title
												}</span>
												<ul className="grid grid-cols-1 gap-2">
													{
														shortTermGoal.description.map((description, i) => {
															return (
																<li key={description + i}>
																	{description}

																</li>
															)
														})
													}
												</ul>
											</div>
										</div>
									</li>
								)
							})
						}
					</ul>
				</div>
			</div >
		</main >
	)
}

const CallToActionCard = () => {
	return (
		<div
			id="CTA"
			className="grid grid-cols-1 gap-4 shadow-[#D0BCFF] shadow-lg hover:shadow-2xl hover:shadow-[#D0BCFF] hover:-translate-y-1  transition ease-in-out p-4 border-2 border-[#D0BCFF] justify-items-center rounded-md grid grid-cols-1"
		>
			<span className="text-xl md:text-4xl"> Don&apos;t have an account? </span>
			<Link
				href="/signup"
				className="defaultButtonStyling"
			>Sign up for free</Link>
			<span className="text-xl md:text-4xl"> Already have an account? </span>
			<Link
				href="/login"
				className="defaultButtonStyling"
			>Login</Link>
		</div>
	)
}